body {
  background: #fff;
}

body > *:not(#print) {
  display: none;
}

#print {
  opacity: 1;
  margin: 2rem 0;
  display: block !important;
  .Button {
    display: none;
  }
  .print-header {
    svg {
      height: 22px;
      fill: #000;
    }
  }
  .print-footer {
    color: #000;
    margin-top: 3rem;
    div {
      padding-top: 1rem;
      border-top: 1px solid #000;
    }
  }
  .Main > *:first-child {
    margin-top: 0 !important;
  }

  .Background {
    background: #fff !important;
  }


  .Gallery {
    .swiper-wrapper {
      transform: none !important;
      // flex-direction: column;
      flex-wrap: wrap;
    }
  }
  .Gallery-image-print {
    position: static;
    visibility: visible;
  }
  .Gallery-image {
    height: auto;
    padding-bottom: 0;
    background: none !important;
    border: 1px solid #ddd;
  }
  .Gallery-slide {
    width: 100% !important;
    margin: 0 0 1rem 0 !important;
    &:not(:first-child) {
      display: none;
    }
  }
  .Gallery-navigation,
  .Gallery-pagination {
    display: none;
  }

  .u-section-spacer-pb {
    padding-bottom: 0 !important;
  }

  .Wishlist {
    padding-top: 3rem;
  }

  .Wishlist-content,
  .Wishlist-item-actionCol,
  .Wishlist-item-image,
  .Wishlist-mainCta {
    display: none;
  }

  .Wishlist-item-image-print {
    display: block;
    width: 100%;
  }

  .Wishlist-item-titleCol {
    margin: 0;
  }

  .Wishlist-item-mainContent {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Wishlist-item-imageCol {
    width: 80px !important;
    max-width: 80px;
    min-width: 80px;
  }

  .Wishlist-item-inputGroup {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    button {
      display: none;
    }
  }

  .Wishlist-item-quantity {
    display: block;
  }

  .Wishlist-input.form-control {
    border: none;
    padding: 0;
    line-height: inherit;
    width: 1rem;
    text-align: right;
    height: auto;
  }

}
